import * as React from 'react';

import { FlagGroup } from '@atlaskit/flag';
import { ModalTransition } from '@atlaskit/modal-dialog-next';
import { OAuthAcceptOrDenyOptions } from '@atlassian/bitbucket-connect-js';

import OAuthErrorFlag from './error';
import OAuthFlag from './flag';
import OAuthModalDialog from './modal';
import { OAuthModule, OAuthModulePartial, OAuthScope } from './types';

type OAuthProps = {
  addonManager: any;
};

type OAuthState = {
  flags: OAuthModule[];
  scopes: OAuthScope[];
  hasError: boolean;
  modalDialog?: OAuthModule;
};

type FlagProp = OAuthModulePartial | boolean;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

const emptyFlag = {
  moduleId: '',
  name: '',
  scopes: '',
  accept: noOp,
  deny: noOp,
};

const addFlagToGroup = (
  flags: OAuthModule[],
  ...flagProps: FlagProp[]
): OAuthModule[] => {
  // eslint-disable-next-line no-param-reassign
  flags = flags.slice();
  flags.unshift(
    flagProps.reduce<OAuthModule>(
      (obj: OAuthModule, props: FlagProp) =>
        typeof props === 'object' ? { ...obj, ...props } : obj,
      { ...emptyFlag }
    )
  );
  return flags;
};

const getFlagState =
  (...flagProps: FlagProp[]) =>
  (state: OAuthState) => {
    return {
      flags: addFlagToGroup(state.flags, ...flagProps),
    };
  };

export class OAuth extends React.PureComponent<OAuthProps, OAuthState> {
  unlisten: Function;
  state: OAuthState = {
    flags: [],
    scopes: [],
    hasError: false,
  };

  componentDidMount(): void {
    // setup listener for auth requests
    this.unlisten = this.props.addonManager.handleOAuthAcceptOrDeny(
      (opts: OAuthAcceptOrDenyOptions, accept: Function, deny: Function) => {
        this.props.addonManager.getOAuthScopes(
          (scopes: OAuthScope[]) => {
            // If request to getScopes() is successful, show the accept/deny flag. It's also possible
            // to skip the flag and directly show the modal by supplying the "skipFlag" option.
            if (opts.skipFlag) {
              this.setState({ scopes });
              this.showModalDialog({
                ...opts,
                accept: () => this.onAcceptOrDeny(opts.moduleId, true, accept),
                deny: () => this.onAcceptOrDeny(opts.moduleId, false, deny),
              });
            } else {
              this.setState(state => ({
                scopes,
                flags: addFlagToGroup(state.flags, opts, {
                  accept: () =>
                    this.onAcceptOrDeny(opts.moduleId, true, accept),
                  deny: () => this.onAcceptOrDeny(opts.moduleId, false, deny),
                }),
              }));
            }
          },
          () => {
            // If request to getScopes() fails, then call deny handler
            // and then handler the error
            this.setState({ hasError: true });
            deny();
          }
        );
      }
    );
    // This will be called after the user accepts the oauth request and the AddonManager.getToken() completes
    // It is called on both successful (isAccepted=true) and failed requests (isAccepted=false)
    this.props.addonManager.setGrantOAuthHandler(
      (opts: OAuthAcceptOrDenyOptions, isAccepted: boolean) => {
        if (opts.skipFlag && isAccepted) {
          // If skipFlag is causing the flag to be skipped and the modal shown immediately, there's
          // no need to display the "Granted" flag.
          return;
        }
        this.setState(
          getFlagState(
            opts,
            {
              deny: () => this.removeFlag(opts.moduleId),
            },
            isAccepted && { denied: false },
            !isAccepted && { hasError: true }
          )
        );
      }
    );
  }

  componentWillUnmount(): void {
    if (typeof this.unlisten === 'function') {
      this.unlisten();
    }
    this.props.addonManager.setGrantOAuthHandler(null);
  }

  dismissErrorFlag = (): void => this.setState({ hasError: false });

  removeFlag = (moduleId: string): void => {
    let flags = this.state.flags.slice();
    flags = flags.filter(({ moduleId: id }) => moduleId !== id);
    this.setState({ flags, modalDialog: undefined });
  };

  onAcceptOrDeny = (
    moduleId: string,
    isAccepted: boolean,
    callback: Function
  ): void => {
    let flag = this.state.flags.find(({ moduleId: id }) => moduleId === id);
    const removeFlag = () => this.removeFlag(moduleId);
    if (!flag) {
      // If there's no flag present, it means the "skipFlag" option was used when triggering the
      // authorisation flow. In this case we just hide the modal and then call the provided callback.
      if (this.state.modalDialog) {
        flag = this.state.modalDialog;
        this.setState({ modalDialog: undefined });
      }
    } else {
      removeFlag();
    }
    if (!isAccepted) {
      this.props.addonManager.denyOAuthConsumer(
        moduleId,
        () => {
          // onSuccess
          if (flag) {
            this.setState(
              getFlagState(flag, {
                deny: removeFlag,
                denied: true,
              })
            );
          }
        },
        () => {
          // onFailed
          if (flag) {
            this.setState(
              getFlagState(flag, {
                deny: removeFlag,
                denied: true,
                hasError: true,
              })
            );
          }
        }
      );
    }
    callback();
  };

  showModalDialog = (mod: OAuthModule): void => {
    this.setState({ modalDialog: mod });
  };

  render(): JSX.Element {
    const { flags, scopes, hasError, modalDialog } = this.state;
    return (
      <React.Fragment>
        <ModalTransition>
          {modalDialog && (
            <OAuthModalDialog scopes={scopes} module={modalDialog} />
          )}
        </ModalTransition>
        <FlagGroup>
          {hasError ? (
            <OAuthErrorFlag key="oauth-error" dismiss={this.dismissErrorFlag} />
          ) : (
            flags.map(mod => (
              <OAuthFlag
                key={mod.moduleId}
                module={mod}
                scopes={scopes}
                showModalDialog={() => this.showModalDialog(mod)}
                managePermissionsUrl={this.props.addonManager.getManagePermissionsUrl()}
              />
            ))
          )}
        </FlagGroup>
      </React.Fragment>
    );
  }
}

export default OAuth;
