import React, { PureComponent, Fragment } from 'react';

import CustomThemeButton from '@atlaskit/button/custom-theme-button';
import ModalDialog, {
  ModalTitle,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@atlaskit/modal-dialog-next';

import { OAuthModule, OAuthScope } from './types';

export type OAuthModalProps = {
  module: OAuthModule;
  scopes: OAuthScope[];
};

export class OAuthModal extends PureComponent<OAuthModalProps> {
  static ListItem = ({ name, description, icon }: OAuthScope) => (
    <li key={name} style={{ display: 'flex' }}>
      <span
        className={`aui-icon aui-icon-small ${icon}`}
        style={{ minWidth: '24px' }}
      />
      <span>{description}</span>
    </li>
  );
  static List = ({
    scopes,
    module: mod,
  }: {
    scopes: OAuthScope[];
    module: OAuthModule;
  }) => (
    <Fragment>
      <p>
        <strong>{mod.name}</strong> requests access to:
      </p>
      <ul style={{ listStyleType: 'none', padding: '4px 0 0 0' }}>
        {scopes.reduce(
          (arr, { name, description, icon }) =>
            mod.scopes.includes(name)
              ? [
                  ...arr,
                  <OAuthModal.ListItem
                    key={name}
                    name={name}
                    description={description}
                    icon={icon}
                  />,
                ]
              : arr,
          []
        )}
      </ul>
    </Fragment>
  );
  render() {
    const { accept, deny } = this.props.module;
    return (
      <ModalDialog
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <ModalHeader>
          <ModalTitle>Review permissions</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <OAuthModal.List {...this.props} />
        </ModalBody>
        <ModalFooter>
          <CustomThemeButton onClick={accept} appearance="primary">
            Allow
          </CustomThemeButton>
          <CustomThemeButton onClick={deny} appearance="subtle">
            Deny
          </CustomThemeButton>
        </ModalFooter>
      </ModalDialog>
    );
  }
}

export default OAuthModal;
